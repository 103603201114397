<template>
  <div
    v-if="show"
    class="h-r18-alert"
    @click.stop=""
  >
    <div class="alert-container">
      <h1 class="h-mb-24">
        你滿18歲了嗎?
      </h1>
      <p class="h-mb-24">
        以下網站可能涉及成人內容，限定為年滿18歲已具有完整行為能力，並同意接受本站內容，及各項條款之網友才可瀏覽，未滿18歲謝絕進入。
      </p>
      <p class="h-noselect h-mb-24">
        <label><input type="checkbox" @change="onChange"> 不再提醒</label>
      </p>
      <button
        class="h-mb-24 h-button"
        @click.stop="handleApprove"
      >
        <img class="h-mr-8" src="@/assets/image/thumbs-up-solid.svg">是，我已年滿18歲並同意
      </button>
      <br>
      <a
        type="link"
        class="txt-color--primary"
        href="about:blank"
      >
        不，我還未滿18歲
      </a>
    </div>
  </div>
</template>
<script>
import qs from 'qs'

export default {
  name: 'HR18Alert',
  data() {
    return {
      show: false,
      setCookie: false
    }
  },
  mounted() {
    const queryString = location.search.slice(1)
    const query = queryString ? qs.parse(queryString) : {}
    const gt18 = query.gt18 ? query.gt18 : false
    const greaterThan18 = this.$cookies.get('h365-gt18') || false
    if (!greaterThan18 && gt18 === 'false') this.show = true
  },
  methods: {
    onChange(e) {
      this.setCookie = e.target.checked
    },
    handleApprove() {
      if (this.setCookie)
        this.$cookies.set('h365-gt18', true, '1y')
      else this.$cookies.set('h365-gt18', true, 0)
      this.show = false
    },
    handleCancel() {
      window.location.href = 'about:blank'
    }
  }
}
</script>
<style lang="scss" scoped>
.h-r18-alert {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: rgba(255, 255, 255, 0.60);
  backdrop-filter: blur(12px);
  &-bg {
    height: 100vh;
    width: 100vw;
    transform: scaleX(2);
  }
  .alert-container {
    position: absolute;
    padding: 32px;
    border-radius: 16px;
    background-color: #ffffff;
    color: #595959;
    max-width: 482px;
    width: calc(100% - 32px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    text-align: center;
    box-sizing: border-box;
    h1 {
      font-size: 20px;
      line-height: 28px;
    }
    p {
      font-size: 14px;
      line-height: 22px;
    }
    .h-button {
      border: none;
      padding: 16px;
      border-radius: 50px;
      width: 100%;
      background-color: #FFC53D;
      color: #262626;
      font-weight: bold;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>