import axios from 'axios'

const getLocalJson = axios.create({
  baseURL: '/static/json'
})

const getCloudStorage = axios.create({
  baseURL: `${process.env.VUE_APP_CLOUD_STORAGE_URL}/storage/v1/b/h365-landing-page/o`
})

export {
  getLocalJson,
  getCloudStorage
}