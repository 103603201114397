<template>
  <div id="app" class="homepage">
    <input type="checkbox" name="" id="menu_control" />
    <div
      v-if="backgroundImage"
      class="full_bg"
      :style="`background-image: url(${imageUrl}/${backgroundImage});`"
    >
      <a :href="`${backgroundLink}${queryString ? '?' + queryString : ''}`"></a>
    </div>
    <header v-if="!hiddenHeader" class="header">
      <div class="header-container">
        <a :href="`${h365Url}${langMap}${queryString ? '?' + queryString : ''}`">
          <div class="logo">
            <img :src="`${imageUrl}/${logoImage}`" alt="pic" />
          </div>
        </a>
        <label
          v-if="!hiddenContactUs || !hiddenFAQ || !hiddenPrivacyPolicy"
          for="menu_control"
          class="menu_btn"
          @click="showMenu = !showMenu">
        </label>
        <nav
          v-if="!hiddenContactUs || !hiddenFAQ || !hiddenPrivacyPolicy"
          class="main-nav"
          :class="{'main-nav--show': showMenu}"
        >
          <ul>
            <li v-if="!hiddenContactUs">
              <a
                href="mailto:h365.cs@gmail.com"
                class="custom-navbar"
              >
                {{ $t('contactUs') }}
              </a>
            </li>
            <li v-if="!hiddenFAQ">
              <a
                :href="`${h365Url}${langMap}/qa/${queryString ? '?' + queryString : ''}`"
                class="custom-navbar"
              >
                {{ $t('FAQ') }}
              </a>
            </li>
            <li v-if="!hiddenPrivacyPolicy">
              <a
                :href="`${h365Url}${langMap}/privacy-policy/${queryString ? '?' + queryString : ''}`"
                class="custom-navbar"
              >
                {{ $t('privacyPolicy') }}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
    <section class="game-contant" :style="hiddenHeader ? 'top: 0px;' : ''">
      <section class="hero-banner-area">
        <div class="hero-banner-container">
          <swiper ref="heroBanner" :options="swiperOption">
            <swiper-slide
              v-for="(slide, index) in slides"
              :key="index"
            >
              <a :href="`${slide.link}${queryString ? '?' + queryString : ''}`">
                <img :src="`${imageUrl}/${slide.image}`" alt="pic"/>
              </a>
            </swiper-slide>
            <div class="swiper-button-next" slot="button-next"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
        <div class="game-title">
          {{ $t('bestSellingGames') }}
        </div>
      </section>
      <section class="game-area">
        <div class="game-container">
          <a
            v-for="(game, index) in games"
            :href="`${game.link}${queryString ? '?' + queryString : ''}`"
            :key="index"
          >
            <div class="game">
              <div class="pic" :class="{ 'pic--coming-soon': $i18n.locale === 'en-US' && !game.supportEnglish }">
                <img :src="`${imageUrl}/${game.image}`" alt="pic" />
              </div>
              <div class="txt">
                <h2>{{ game.name }}</h2>
                <p>
                  {{ game.description }}
                </p>
              </div>
            </div>
          </a>
        </div>
      </section>
    </section>
    <footer v-if="!hiddenFooter" class="footer">
      <div class="footer-content">
        <a :href="`${h365Url}${langMap}${queryString ? '?' + queryString : ''}`">
          <div class="footer-logo">
            <img :src="`${imageUrl}/${logoImage}`" />
          </div>
        </a>
        <div class="footer-txt" v-html="$t('footerMessage')"></div>
      </div>
      <div class="copyright">copyright ©H365 All rights reserved.</div>
    </footer>
    <h-r18-alert />
  </div>
</template>

<script>
import HR18Alert from '@/components/HR18Alert.vue'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import { getCloudStorage } from '../plugin/axios'
import qs from 'qs'

import './assets/css/main.scss'

export default {
  name: 'App',
  components: {
    Swiper,
    SwiperSlide,
    HR18Alert
  },
  directives: {
    swiper: directive
  },
  data() {
    return {
      queryString: '',
      showMenu: false,
      configData: {},
      swiperOption: {
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: {
          delay: 4500,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  async created() {
    const location = window.location.href
    const index = location.indexOf('?')
    this.queryString = index > -1 ? location.slice(index + 1) : ''
    const query = index > -1 ? qs.parse(this.queryString) : {}
    const filename = query.utm_source ? query.utm_source : 'default'
    let url = encodeURIComponent(`config/minisite/${filename}.json`)
    try {
      const { data } = await getCloudStorage.get(url,
        { params: { alt: 'media' } })
      if (data) this.configData = data
      if (data?.lang && this?.$i18n?.messages?.[data.lang])
        this.$i18n.locale = data?.lang
    }
    catch (error) {
      console.error(error)
      url = encodeURIComponent('config/minisite/default.json')
      const { data } = await getCloudStorage.get(url,
        { params: { alt: 'media' } })
      if (data) this.configData = data
    }
  },
  computed: {
    games() {
      if (this.configData?.games && this.configData.games.length > 0) {
        const width = this.isDesktop() ? 256 : 384
        return this.configData.games
          .map(item => {
            item.image = `${width}/${this.imagePath}/${item.image}`
            return item
          })
      }
      return []
    },
    slides() {
      if (this.configData?.slides && this.configData.slides.length > 0) {
        const width = this.isDesktop() ? 1360 : 428
        return this.configData.slides
          .map(item => {
            item.image = `${width}/${this.imagePath}/${item.image}`
            return item
          })
      }
      return []
    },
    backgroundLink() {
      if (this.configData?.backgroundLink)
        return this.configData.backgroundLink
      return ''
    },
    backgroundImage() {
      if (this.configData?.backgroundImage) {
        const width = this.isDesktop() ? 1920 : 0
        if (width === 0) return ''
        const image = this.configData.backgroundImage
        return `${width}/${this.imagePath}/${image}`
      }
      return ''
    },
    h365Url() {
      if (this.configData?.logoURL)
        return this.configData.logoURL
      return ''
    },
    imageUrl() {
      if (process.env.VUE_APP_IMAGE_URL)
        return process.env.VUE_APP_IMAGE_URL
      return ''
    },
    imagePath() {
      if (process.env.VUE_APP_IMAGE_PATH)
        return process.env.VUE_APP_IMAGE_PATH
      return ''
    },
    hiddenHeader() {
      if (this.configData?.hiddenHeader)
        return this.configData.hiddenHeader
      return false
    },
    hiddenFooter() {
      if (this.configData?.hiddenFooter)
        return this.configData.hiddenFooter
      return false
    },
    hiddenContactUs() {
      if (this.configData?.hiddenContactUs)
        return this.configData.hiddenContactUs
      return false
    },
    hiddenFAQ() {
      if (this.configData?.hiddenFAQ)
        return this.configData.hiddenFAQ
      return false
    },
    hiddenPrivacyPolicy() {
      if (this.configData?.hiddenPrivacyPolicy)
        return this.configData.hiddenPrivacyPolicy
      return false
    },
    langMap() {
      if (this.$i18n.locale === 'zh-CN')
        return ''
      return `/${this.$i18n.locale}`
    },
    logoImage() {
      const width = this.isDesktop() ? 140 : 119
      return `${width}/${this.imagePath}/h365-logo.png`
    }
  },
  methods: {
    isDesktop() {
      return document.body.getBoundingClientRect().width > 1024
    }
  }
}
</script>