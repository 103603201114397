import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import i18n from './i18n'

Vue.config.productionTip = false

Vue.use(VueAxios, axios, VueAwesomeSwiper)
Vue.use(VueCookies)

new Vue({
  i18n,
  render: h => h(App)
}).$mount('#app')